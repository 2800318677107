<script>
  import store from "../../store/index";

  export default {
    data() {
      return {
        timeOutHome: null,
        info: null,
      };
    },
    computed: {
      saved: function () {
        return this.$store.state.signup.saved || {};
      },
    },
    methods: {
      async getData() {
        this.info = await store.getters["localeLang"];
      },
      goHome() {
        this.$router.push({ path: "/" });
      },
      timeOutToGoHome() {
        clearTimeout(this.timeOutHome);
        this.timeOutHome = setTimeout(this.goHome, 15000);
      },
      waitForGtag(callback) {
        if (typeof window.gtag !== "undefined") {
          callback();
        } else {
          setTimeout(() => waitForGtag(callback), 300);
        }
      },
      convertion() {
        this.waitForGtag(() => {
          window.gtag("event", "conversion", { send_to: "AW-796073199/0EMCCP3K_6sCEO-5zPsC", event_label: `Register ${this.saved.name}` });
          window.gtag("event", "conversion", { send_to: "AW-949852427/e0jBCIHp4sUDEIuy9sQD", event_label: `Register ${this.saved.name}` });
          window.gtag("event", "conversion", { send_to: "UA-167776108-1/e0jBCIHp4sUDEIuy9sQD", event_label: `Register ${this.saved.name}` });
        });
      },
    },
    async mounted() {
      localStorage.removeItem("successRegister");

      this.convertion();
      await this.getData();
      this.timeOutToGoHome();
    },
    beforeDestroy() {
      clearTimeout(this.timeOutHome);
    },
  };
</script>

<template>
  <section class="signupSuccess">
    <div class="signupSuccess__wrapper" v-if="info">
      <h1 class="signupSuccess__tittle">Models 1A</h1>
      <div class="signupSuccess__text"><iconic name="successFullCheck" class="signupSuccess__icon" />{{ info.words.register.text }}</div>
      <div class="signupSuccess__subText">
        <span class="signupSuccess__name">{{ saved.name }},</span>
        <span>{{ info.words.register.subText }}</span>
      </div>
      <div class="signupSuccess__redirectText blink">{{ info.words.register.redirectText }}...</div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  .signupSuccess {
    @include Flex(row);
    width: 100%;
    height: 100svh;
    &__wrapper {
      @include Flex(column);
      padding: $mpadding * 2;
      gap: $space-16;
      text-align: center;
      background: #f6f6f6;
      width: 100%;
    }
    &__tittle {
      color: $primary_color;
      font-size: $space-38;
      font-family: $third_font;
      font-weight: 500;
    }
    &__text {
      text-transform: uppercase;
      font-size: $space-32;
      font-family: BebasKai, $first_font;
      color: $primary_color;
    }
    &__icon {
      margin-right: $space-8;
    }
    &__subText {
      text-transform: none;
      font-family: $first_font;
      font-size: 17px;
      font-weight: 600;
    }
    &__name {
      margin: 5px;
    }
    &__redirectText {
      color: #727272;
      text-align: center;
      font-family: $first_font;
      font-size: 18px;
      &.blink {
        animation: blinker 2s linear infinite;
      }
    }
    @media (min-width: $tablet_width) {
      &__tittle {
        font-size: $space-64;
      }
      &__text {
        font-size: $space-32;
      }
      &__subText {
        width: 60%;
        font-size: $space-20;
      }
    }

    @media (min-width: 768px) and (max-width: 980px) {
      &__wrapper {
        margin-top: 0;
        height: 40vh;
      }
    }
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
</style>
